<template>
  <Loading v-if="isLoading" />
  <div v-else class="delivery-boys-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">
          {{ $t("delivery.delivery_boys") }}
        </h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        style-class="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          searchFn: searchFunction,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'delivery_boy'"
            class="text-nowrap"
          >
            {{ $t("delivery.delivery_boy") }}
          </span>
          <span
            v-else-if="props.column.label === 'shift_time'"
            class="text-nowrap"
          >
            {{ $t("delivery.shift_time") }}
          </span>
          <span
            v-else-if="props.column.label === 'delivery_area'"
            class="text-nowrap"
          >
            {{ $t("delivery.delivery_area") }}
          </span>
          <span
            v-else-if="props.column.label === 'orders_per_month'"
            class="text-nowrap"
          >
            {{ $t("delivery.orders_per_month") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("delivery.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'user'" class="text-nowrap">
            {{ props.row.user.first_name + " " + props.row.user.last_name }}
          </span>

          <span
            v-else-if="props.column.field === '#'"
            class="text-nowrap custom-toggle"
          >
            {{ props.row.index }}
          </span>

          <span v-else-if="props.column.field === 'shift'" class="text-nowrap">
            {{
              props.row.shift
                ? props.row.shift.start_time + " - " + props.row.shift.end_time
                : $t("g.no_selected")
            }}
          </span>

          <span
            v-else-if="props.column.field === 'delivery_area'"
            class="text-nowrap"
          >
            {{ props.row.shift ? props.row.shift.area : $t("g.no_selected") }}
          </span>

          <span
            v-else-if="props.column.field === 'order_per_month'"
            class="text-nowrap"
          >
            {{ props.row.order_per_month }}
          </span>

          <span
            v-else-if="props.column.field === 'status'"
            class="text-nowrap custom-toggle"
          >
            <b-form-checkbox
              :disabled="
                globalPermission['delivery-boys'] &&
                !globalPermission['delivery-boys']['change-state']
              "
              v-model="props.row.is_active"
              switch
              :value="1"
              :unchecked-value="0"
              @change="(e) => handleDeliveryVisibility(e, props.row.id)"
            />
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              globalPermission['delivery-boys'] &&
              (globalPermission['delivery-boys'].update ||
                globalPermission['delivery-boys'].destroy)
            "
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="
                    globalPermission['delivery-boys'] &&
                    globalPermission['delivery-boys'].update
                  "
                  :to="{
                    name: 'edit-delivery-boy',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission['delivery-boys'] &&
                    globalPermission['delivery-boys'].destroy
                  "
                  @click="deleteDelivery(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "DeliveryBoysList",
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    Loading,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "delivery_boy",
          field: "user",
          sortable: true,
        },
        {
          label: "shift_time",
          field: "shift",
          sortable: false,
        },
        {
          label: "delivery_area",
          field: "delivery_area",
          sortable: false,
        },
        {
          label: "orders_per_month",
          field: "order_per_month",
          sortable: false,
        },
        {
          label: "status",
          field: "status",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getDeliveryBoysList();
  },
  mounted() {
    if (
      this.globalPermission["delivery-boys"] &&
      !(
        this.globalPermission["delivery-boys"].update ||
        this.globalPermission["delivery-boys"].destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "Actions"
      );
    }
    s;
  },
  methods: {
    async getDeliveryBoysList() {
      try {
        const response = (await this.$http.get("admin/delivery-boys")).data
          .data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_active = +el.is_active);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteDelivery(deliveryId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeleteDelivery(deliveryId),
          "The Delivery boy has been successfully deleted"
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handleDeleteDelivery(deliveryId) {
      this.$http
        .delete(`admin/delivery-boys/${deliveryId}`)
        .then((res) => {
          if (res.status === 200 || res.status === 200) {
            this.getDeliveryBoysList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleDeliveryVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(
          `admin/delivery-boys/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    searchFunction(row, col, cellValue, searchTerm) {
      const searchValue = `${row.user.first_name?.toLowerCase()} ${row.user.last_name?.toLowerCase()}`;
      return searchValue.includes(searchTerm.toLowerCase());
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
